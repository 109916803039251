/* eslint-disable @typescript-eslint/no-explicit-any */
import { filtraApostila } from '@domain/discipline'
import httpClient from '@gateway/generalHttpRequest'
import { adapterDisciplines, formDataDiscipline } from './adapter-disciplines'

const consulta1 = (courseId: any, subjectId: any) => {
  const path = `offers/api/v1/course/subject_treeview/${courseId}/${subjectId}`
  return new Promise((resolve) => {
    httpClient
      .get(path)
      .then((response) => response.json())
      .then((jsonData) => resolve(jsonData))
  })
}

export const getDisciplines = async (courseId: number): Promise<any> => {
  // const path = `/offers/api/v1/course/subjects/${courseId}`
  // const response = await httpClient.get(path)
  // const responseJson = await response.json()
  // const disciplinaAdapatada = adapterDisciplines(
  //   responseJson as formDataDiscipline
  // )
  // return filtraApostila(disciplinaAdapatada)

  const path = `/offers/api/v1/course/subjects/${courseId}`
  const response = await httpClient.get(path)
  const responseJson = await response.json()

  const lista = (responseJson as any).data.map((discipline: any) => {
    return consulta1(courseId, discipline.disciplina_id)
  })

  const responseBugada = await Promise.all(lista)

  const disciplinaAdapatada = adapterDisciplines({
    data: responseBugada,
  } as formDataDiscipline)

  return filtraApostila(disciplinaAdapatada)
}
